import React from 'react';
import Layout from '../shared/components/Layouts/Inner';
import SEO from '../shared/components/SEO';
import ContainerFixed from '../shared/components/Layouts/ContainerFixed';

const IndexPage = () => (
  <Layout url="privacy">
    <SEO title="Privacy Policy" />

    <ContainerFixed>
      <div>
        <h1
          style={{
            textTransform: 'uppercase',
            textAlign: 'center',
            letterSpacing: 1,
            marginTop: '3rem',
          }}
        >
          Privacy Policy
        </h1>
        <h3>1.Introduction</h3>
        <p>
          1.1 We are committed to safeguarding the privacy of our website visitors and service
          users.
        </p>
        <p>
          1.2 This policy applies where we are acting as a data controller with respect to the
          personal data of our website visitors and service users; in other words, where we
          determine the purposes and means of the processing of that personal data.
        </p>
        <p>
          1.3 We use cookies on our website. Insofar as those cookies are not strictly necessary for
          the provision of our website and services, we will ask you to consent to our use of
          cookies when you first visit our website.
        </p>
        <p>
          1.4 In this policy, &quot;we&quot;, &quot;us&quot; and &quot;our&quot; refer to
          Maidupnorth.
        </p>
      </div>
      <div>
        <h3>2.Credit</h3>
        <p>
          2.1 This document was created using a template from SEQ Legal
          (https://seqlegal.com/free-legal-documents/privacy-policy).
        </p>
      </div>
      <div>
        <h3>3.How we use your personal data</h3>
        <p>
          3.1 In this Section 3 we have set out: (a) the general categories of personal data that we
          may process; (b) [in the case of personal data that we did not obtain directly from you,
          the source and specific categories of that data]; (c) the purposes for which we may
          process personal data; and (d) the legal bases of the processing.
        </p>
        <p>
          3.2 We may process data about your use of our website and services (&quot;usage
          data&quot;). The usage data may include your IP address, geographical location, browser
          type and version, operating system, referral source, length of visit, page views and
          website navigation paths, as well as information about the timing, frequency and pattern
          of your service use. The source of the usage data is stored in Google Analytics. This
          usage data may be processed for the purposes of analysing the use of the website and
          services. The legal basis for this processing is consent OR our legitimate interests,
          namely monitoring and improving our website and services.
        </p>
        <p>
          3.3 We may process your website user account data (&quot;account data&quot;). The account
          data may include your name and email address. The source of the account data is you. The
          account data may be processed for the purposes of operating our website, providing our
          services, ensuring the security of our website and services, maintaining back-ups of our
          databases and communicating with you. The legal basis for this processing is consent OR
          our legitimate interests, namely the proper administration of our website and business OR
          the performance of a contract between you and us and/or taking steps, at your request, to
          enter into such a contract.
        </p>
        <p>
          3.5 We may process information contained in any enquiry you submit to us regarding goods
          and/or services (&quot;enquiry data&quot;). The enquiry data may be processed for the
          purposes of offering, marketing and selling relevant goods and/or services to you. The
          legal basis for this processing is consent OR our legitimate interests, namely the proper
          administration of our website and business OR the performance of a contract between you
          and us and/or the taking steps, at your request, to enter into such a contract.
        </p>
        <p>
          3.6 We may process information relating to transactions, including purchases of goods
          and/or services, that you enter into with us and/or through our website (&quot;transaction
          data&quot;). The transaction data may include your contact details, your card details and
          the transaction details. The source of the transaction data is you and/or our payment
          services provider. The transaction data may be processed for the purpose of supplying the
          purchased goods and/or services and keeping proper records of those transactions. The
          legal basis for this processing is the performance of a contract between you and us and/or
          taking steps, at your request, to enter into such a contract; providing that, if you are
          not the person contracting with us, the legal basis for this processing is our legitimate
          interests, namely the proper administration of our website and business.
        </p>
        <p>
          3.7 We may process information that you provide to us for the purpose of subscribing to
          our email notifications and/or newsletters (&quot;notification data&quot;). The
          notification data may be processed for the purposes of sending you the relevant
          notifications and/or newsletters. The legal basis for this processing is consent OR our
          legitimate interests, namely communications with our website visitors and service users OR
          the performance of a contract between you and us and/or taking steps, at your request, to
          enter into such a contract.
        </p>
        <p>
          3.8 We may process any of your personal data identified in this policy where necessary for
          the establishment, exercise or defence of legal claims, whether in court proceedings or in
          an administrative or out-of-court procedure. The legal basis for this processing is our
          legitimate interests, namely the protection and assertion of our legal rights, your legal
          rights and the legal rights of others.
        </p>
        <p>
          3.9 In addition to the specific purposes for which we may process your personal data set
          out in this Section 3, we may also process any of your personal data where such processing
          is necessary for compliance with a legal obligation to which we are subject, or in order
          to protect your vital interests or the vital interests of another natural person.
        </p>
        <p>
          3.10 Please do not supply any other person&apos;s personal data to us, unless we prompt
          you to do so.
        </p>
      </div>
      <div>
        <h3>4.Providing your personal data to others</h3>
        <p>
          4.1 We may disclose your personal data to our insurers and/or professional advisers
          insofar as reasonably necessary for the purposes of obtaining or maintaining insurance
          coverage, managing risks, obtaining professional advice, or the establishment, exercise or
          defence of legal claims, whether in court proceedings or in an administrative or
          out-of-court procedure.
        </p>
        <p>
          4.2 Your personal data will be held in our website database OR will be stored on the
          servers of our hosting services providers Netlify and Google.
        </p>
        <p>
          4.3 In addition to the specific disclosures of personal data set out in this Section 4, we
          may disclose your personal data where such disclosure is necessary for compliance with a
          legal obligation to which we are subject, or in order to protect your vital interests or
          the vital interests of another natural person. We may also disclose your personal data
          where such disclosure is necessary for the establishment, exercise or defence of legal
          claims, whether in court proceedings or in an administrative or out-of-court procedure.
        </p>
      </div>
      <div>
        <h3>5.International transfers of your personal data</h3>
        <p>
          5.1 In this Section 5, we provide information about the circumstances in which your
          personal data may be transferred to countries outside the European Economic Area (EEA).
        </p>
        <p>
          5.2 The hosting facilities for our website are situated in Europe and the USA. The
          European Commission has made an &quot;adequacy decision&quot; with respect to the data
          protection laws of each of these countries. Transfers to each of these countries will be
          protected by appropriate safeguards, namely the use of standard data protection clauses
          adopted or approved by the European Commission.
        </p>
      </div>
      <div>
        <h3>6.Retaining and deleting personal data</h3>
        <p>
          6.1 This Section 6 sets out our data retention policies and procedure, which are designed
          to help ensure that we comply with our legal obligations in relation to the retention and
          deletion of personal data.
        </p>
        <p>
          6.2 Personal data that we process for any purpose or purposes shall not be kept for longer
          than is necessary for that purpose or those purposes.
        </p>
        <p>
          6.3 We will retain your personal data as follows: (a) usage data will be retained for a
          minimum period of 1 year following the date of collection, and for a maximum period of 6
          years following that date; (b) account data will be retained for a minimum period of 6
          years following the date of closure of the relevant account, and for a maximum period of 6
          years following that date; (d) enquiry data will be retained for a minimum period of 1
          year following the date of the enquiry, and for a maximum period of 6 years following that
          date; (e) transaction data will be retained for a minimum period of 6 years following the
          date of the transaction; (f) notification data will be retained for a minimum period of 1
          month following the date that we are instructed to cease sending the notifications, and
          for a maximum period of 1 year following that date (providing that we will retain
          notification data insofar as necessary to fulfil any request you make to actively suppress
          notifications);
        </p>
        <p>
          6.4 Notwithstanding the other provisions of this Section 6, we may retain your personal
          data where such retention is necessary for compliance with a legal obligation to which we
          are subject, or in order to protect your vital interests or the vital interests of another
          natural person.
        </p>
      </div>
      <div>
        <h3>7.Your rights</h3>
        <p>
          7.1 In this Section 7, we have listed the rights that you have under data protection law.
        </p>
        <p>
          7.2 Your principal rights under data protection law are: (a) the right to access - you can
          ask for copies of your personal data; (b) the right to rectification - you can ask us to
          rectify inaccurate personal data and to complete incomplete personal data; (c) the right
          to erasure - you can ask us to erase your personal data; (d) the right to restrict
          processing - you can ask use to restrict the processing of your personal data; (e) the
          right to object to processing - you can object to the processing of your personal data;
          (f) the right to data portability - you can ask that we transfer your personal data to
          another organisation or to you; (g) the right to complain to a supervisory authority - you
          can complain about our processing of your personal data; and (h) the right to withdraw
          consent - to the extent that the legal basis of our processing of your personal data is
          consent, you can withdraw that consent.
        </p>
        <p>
          7.3 These rights are subject to certain limitations and exceptions. You can learn more
          about the rights of data subjects by visiting
          https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/.
        </p>
        <p>
          7.4 You may exercise any of your rights in relation to your personal data [by written
          notice to us, using the contact details set out below].
        </p>
      </div>
      <div>
        <h3>8.About cookies</h3>
        <p>
          8.1 A cookie is a file containing an identifier (a string of letters and numbers) that is
          sent by a web server to a web browser and is stored by the browser. The identifier is then
          sent back to the server each time the browser requests a page from the server.
        </p>
        <p>
          8.2 Cookies may be either &quot;persistent&quot; cookies or &quot;session&quot; cookies: a
          persistent cookie will be stored by a web browser and will remain valid until its set
          expiry date, unless deleted by the user before the expiry date; a session cookie, on the
          other hand, will expire at the end of the user session, when the web browser is closed.
        </p>
        <p>
          8.3 Cookies do not typically contain any information that personally identifies a user,
          but personal data that we store about you may be linked to the information stored in and
          obtained from cookies.
        </p>
      </div>
      <div>
        <h3>9.Cookies that we use</h3>
        <p>
          9.1 We use cookies for the following purposes: (a) analysis - we use cookies to help us to
          analyse the use and performance of our website and services cookies used for this purpose
          are: _ga (b) cookie consent - we use cookies [to store your preferences in relation to the
          use of cookies more generally cookies used for this purpose are: _trackingOptIn.
        </p>
      </div>
      <div>
        <h3>10.Cookies used by our service providers</h3>
        <p>
          10.1 Our service providers use cookies and those cookies may be stored on your computer
          when you visit our website.
        </p>
        <p>
          10.2 We use Google Analytics. Google Analytics gathers information about the use of our
          website by means of cookies. The information gathered is used to create reports about the
          use of our website. You can find out more about Google&apos;s use of information by
          visiting https://www.google.com/policies/privacy/partners/ and you can review
          Google&apos;s privacy policy at https://policies.google.com/privacy. The relevant cookies
          are: _ga, _gat.
        </p>
      </div>
      <div>
        <h3>11.Managing cookies</h3>
        <p>
          11.1 Most browsers allow you to refuse to accept cookies and to delete cookies. The
          methods for doing so vary from browser to browser, and from version to version. You can
          however obtain up-to-date information about blocking and deleting cookies via these links:
          (a) https://support.google.com/chrome/answer/95647 (Chrome); (b)
          https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences
          (Firefox); (c) https://help.opera.com/en/latest/security-and-privacy/ (Opera); (d)
          https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies
          (Internet Explorer); (e)
          https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac
          (Safari); and (f)
          https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy (Edge).
        </p>
        <p>
          11.2 Blocking all cookies will have a negative impact upon the usability of many websites.
        </p>
        <p>
          11.3 If you block cookies, you will not be able to use all the features on our website.
        </p>
      </div>
      <div>
        <h3>12.Amendments</h3>
        <p>
          12.1 We may update this policy from time to time by publishing a new version on our
          website.
        </p>
        <p>
          12.2 You should check this page occasionally to ensure you are happy with any changes to
          this policy.
        </p>
        <p>12.3 We will notify you of changes to this policy by email.</p>
      </div>
    </ContainerFixed>
  </Layout>
);

export default IndexPage;
